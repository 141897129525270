<template>
  <WIPComponent />
</template>

<script>
import {defineComponent} from "vue";
import WIPComponent from "@/shared/WIPComponent";

export default defineComponent({
  name: "NotificationsComponent",
  components: {WIPComponent}
})
</script>

<style scoped>

</style>