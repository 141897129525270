import Keycloak from "keycloak-js";


console.log(process.env.VUE_APP_DEPLOYMENTS_ENDPOINT)
console.log(Keycloak)
// const keycloak = new Keycloak()
const keycloak = new Keycloak({
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
});

interface CbOneParam<T1 = void, T2 = void> {
    (param1: T1): T2;
}

/**
 * Keycloak instance initialization
 *
 * @param onAuthenticatedCB
 */

const Login = (onAuthenticatedCB: CbOneParam) => {
    keycloak
        .init({onLoad: "login-required"})
        .then(function (authenticated) {
            authenticated ? onAuthenticatedCB() : alert("non authenticated user");
            if (authenticated) {
                localStorage.setItem("token", <string>keycloak.token)
            }
            // return authenticated
        })
        .catch((e) => {
            console.dir(e);
            console.log(e)
        });
};


const Logout = () => {
    return keycloak.logout()
}

const UpdateToken = () => {
    return keycloak.updateToken(30)
}

const getToken = () => {
    return keycloak.token
}

const getUser = () => {
    return keycloak.tokenParsed
}


const KeycloakService = {
    CallLogin: Login,
    CallLogout: Logout,
    User: getUser,
    Token: getToken,
    UpdateToken
}

export default KeycloakService