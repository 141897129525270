import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c032bc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["toast", toast.success ? 'success' : 'fail']),
        ref_for: true,
        ref: (el) => {_ctx.registerRemove(el, index)},
        style: {"margin-top":"10px"},
        key: toast
      }, [
        _createTextVNode(_toDisplayString(toast.label) + " ", 1),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"10px","cursor":"pointer"},
          "aria-placeholder": "Remove",
          icon: ['fas', 'xmark'],
          onClick: (event) => _ctx.manualRemove(index, event.target)
        }, null, 8, ["onClick"])
      ], 2))
    }), 128))
  ]))
}