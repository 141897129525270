import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "view" }
const _hoisted_3 = { class: "view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_VueSpinner = _resolveComponent("VueSpinner")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_ToastsComponent = _resolveComponent("ToastsComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SideBar, {
        onLogout: _ctx.logout,
        user: _ctx.user
      }, null, 8, ["onLogout", "user"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ], 512), [
      [_vShow, !_ctx.loaderStore.loading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VueSpinner, {
        size: "40",
        color: "#004AAD"
      })
    ], 512), [
      [_vShow, _ctx.loaderStore.loading]
    ]),
    _createVNode(_component_ModalComponent),
    _createVNode(_component_ToastsComponent)
  ], 64))
}