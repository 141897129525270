import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00eb874c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-content"
}
const _hoisted_2 = {
  key: 1,
  class: "modal-content"
}
const _hoisted_3 = {
  key: 2,
  class: "modal-content"
}
const _hoisted_4 = {
  key: 3,
  class: "modal-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModal = _resolveComponent("DeleteModal")!
  const _component_RequirementsModal = _resolveComponent("RequirementsModal")!
  const _component_PortMappingModal = _resolveComponent("PortMappingModal")!
  const _component_ReadOnlyDataModal = _resolveComponent("ReadOnlyDataModal")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([{'blur': _ctx.modalStore.show}, "backdrop"])
    }, null, 2), [
      [_vShow, _ctx.modalStore.show]
    ]),
    _withDirectives(_createElementVNode("div", {
      style: _normalizeStyle(_ctx.modalStore?.styles),
      class: "aeros-modal"
    }, [
      (_ctx.modalStore.template === 'delete')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_DeleteModal, {
              onBack: _ctx.hideModal,
              onConfirm: _cache[0] || (_cache[0] = (data) => _ctx.confirmDelete(data)),
              data: _ctx.modalStore.data.id
            }, null, 8, ["onBack", "data"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.modalStore.template === 'requirements')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_RequirementsModal, {
              onBack: _ctx.hideModal,
              onConfirm: _cache[1] || (_cache[1] = (result) => _ctx.confirmData(result)),
              data: _ctx.modalStore.data
            }, null, 8, ["onBack", "data"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.modalStore.template === 'portMapping')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_PortMappingModal, {
              onBack: _ctx.hideModal,
              onConfirm: _cache[2] || (_cache[2] = (portMapping) => _ctx.confirmData(portMapping)),
              data: _ctx.modalStore.data
            }, null, 8, ["onBack", "data"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.modalStore.template === 'ReadOnly')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_ReadOnlyDataModal, {
              data: _ctx.modalStore.data
            }, null, 8, ["data"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("i", {
        class: "close",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
      ])
    ], 4), [
      [_vShow, _ctx.modalStore.show]
    ])
  ], 64))
}