<template>
  <WIPComponent />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import WIPComponent from "@/shared/WIPComponent.vue";

export default defineComponent({
  name: "BenchmarkingToolComponent",
  components: {WIPComponent}
})
</script>

<style scoped>

</style>